  <template >
  
  <section
    :id="'vp' + computedData.intViewportId"
    :class="'dmaperformance__' + computedData.teasertype"
  >

    <div
      @mousemove="handleTouchMoveComponent"
      :class="
        'dmaperformance__' +
        (computedData.teasertype.includes('slider') ? 'slider' : 'teaser') +
        '--' +
        (computedData.teasertype.includes('slider') ? 'slider' : 'teaser') +
        '_body flexbox '
        + (isFading ? ' fading ':'')
      "
      :data-tpl="computedData.teasertype"
      v-if="computedData.teasers && computedData.teasertype.includes('slider')"
      :style="'width:100%'"
      :data-index="activeSlide + 1"

    >
      <!-- :style="'width:' + 100 * computedData.teasers.length + 'vw'" -->

      <slide
        :data-rotations="rotationindex"
        @swipeslider="goToNextSlide($event, $event.direction.id)"
        @changedSlide="goToNextSlide($event, i)"
        @changedSlideback="goToNextSlideBack($event, i)"

        @click="goToNextSlide($event, i)"
        :class="
          'dmaperformance__' +
          (computedData.teasertype.includes('slider') ? 'slider' : 'teaser') +
          '--' +
          (computedData.teasertype.includes('slider') ? 'slider' : 'teaser') +
          '_' +
          (computedData.teasertype.includes('slider') ? 'slide' : 'teaser') +
          ' ' +
          (computedData.teasertype.includes('slider') ? 'slide' : 'teaser') +
          i +
          ' ' +
          computedData.teasertype +
          ' ' +
          (teaser === objActiveSlide ? 'active' : '') +
          (i < activeSlide ? ' past' : '') +
          (i < activeSlide - 1 ? ' past2steps' : '') +
          (i < activeSlide - 2 ? ' past3steps' : '') +
          (i < activeSlide - 3 ? ' past4steps' : '') +
          (teaser === objNextSlide ? ' next' : '') +
          (isEdgeSlide && teaser === objNextSlide ? ' edge' : '') +
          (teaser === objPrevSlide ? ' prev' : '') +
          (isEdgeSlide && teaser === objPrevSlide ? ' edge' : '') +
          ' ' +
          (teaser === objActiveSlide && undefined !== objActiveSlide.class
            ? objActiveSlide.class
            : '')
        "
        :style="computedStyle"
        :data="teaser"
        :type="computedData.teasertype"
        :hasFlags="computedData.hasFlags?computedData.hasFlags : null"
        :isActive="teaser === objActiveSlide"
        :isNext="teaser === objNextSlide"
        :isPrev="teaser === objPrevSlide"
        :index="i"
        :infinite="computedData.infinite"
        :rotate="willRotate(i)"
        :activeIndex="activeSlide"
        :teleport="
          teaser !== objActiveSlide &&
          teaser !== objNextSlide &&
          teaser !== objPrevSlide
        "
        :length="computedData.teasers.length"
        v-for="(teaser, i) in computedData.teasers"
        :key="
          'slide-' +
          computedData.teasertype +
          '-' +
          computedData.intViewportId +
          i
        "
      />
      <!-- <div class="bg-flags" v-if="computedData.hasFlags && computedData.hasFlags === 'slider'"></div> -->
      <!-- :isPast="i < activeSlide" -->
      <!-- (i < activeSlide ? ' past' : '') +
          (i < activeSlide - 1 ? ' past2steps' : '') +
          (i < activeSlide - 2 ? ' past3steps' : '') +
          (i < activeSlide - 3 ? ' past4steps' : '') +
          (teaser === objNextSlide ? ' next' : '') +
          (isEdgeSlide && teaser === objNextSlide ? ' edge' : '') +
          (teaser === objPrevSlide ? ' prev' : '') +
          (isEdgeSlide && teaser === objPrevSlide ? ' edge' : '') + -->
    </div>

    <Track
      v-if="
        computedData.teasertype === 'city-slider' ||
        computedData.teasertype === 'header-slider'
      "
      @changeSlide="goToNextSlide($event)"
      @changeSlideBack="goToNextSlideBack($event)"
      :length="computedData.teasers.length"
      :index="activeSlide"
    />
    <div
      v-if="computedData.teasertype.includes('slider')"
      :class="
        'dmaperformance__' +
        (computedData.teasertype.includes('slider') ? 'slider' : 'teaser') +
        '--' +
        (computedData.teasertype.includes('slider') ? 'slider' : 'teaser') +
        '_' +
        (computedData.teasertype.includes('slider') ? 'navigation' : 'teaser') +
        ' '
      "
    >
      <button
        @mouseleave,@mouseenter="toggleClass($event.target, 'active', 300)"
        class="btn prevLink"
        :disabled="
          computedData.teasertype.includes('slider') &&
          activeSlide === 0 &&
          !computedData.infinite
            ? 'true'
            : null
        "
        :data-text-before="((computedData.teasertype == 'city-slider') ? (composeLinkTitle(objPrevSlide.url)) : ('Zurück'))"
        @click="
          toggleClass($event.target, 'active', 300);

          ((computedData.teasertype == 'city-slider') ? (redirect(objPrevSlide.url,router)) : (goToNextSlide($event, false)));
        "
      >

        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            width="14.988"
            height="15.609"
            viewBox="0 0 14.988 15.609"
          >
            <defs></defs>
            <g transform="translate(-6 -5.379)">
              <path
                class="a"
                d="M7.5,18H18.866"
                transform="translate(0 -4.817)"
              />
              <path
                class="b"
                d="M18,7.5l5.683,5.683L18,18.866"
                transform="translate(-4.817 0)"
              />
            </g>
          </svg>
        </span>
      </button>
      <button
        class="btn nextLink"
        :disabled="
          nextButtonIsDisabled
            ? 'true'
            : null
        "
        :data-text-before="((computedData.teasertype == 'city-slider') ? (composeLinkTitle(objNextSlide.url)) : ('Weiter'))"
        @click="
          toggleClass($event.target, 'active', 300);

          ((computedData.teasertype == 'city-slider') ? (redirect(objNextSlide.url,router)) : (goToNextSlide($event)));

        "
      >

        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrow"
            width="14.988"
            height="15.609"
            viewBox="0 0 14.988 15.609"
          >
            <defs></defs>
            <g transform="translate(-6 -5.379)">
              <path
                class="a"
                d="M7.5,18H18.866"
                transform="translate(0 -4.817)"
              />
              <path
                class="b"
                d="M18,7.5l5.683,5.683L18,18.866"
                transform="translate(-4.817 0)"
              />
            </g>
          </svg>
        </span>
      </button>
     
    </div>

    <div
      :class="
        'dmaperformance__' +
        (computedData.teasertype.includes('slider') ? 'slider' : 'teaser') +
        '--' +
        (computedData.teasertype.includes('slider') ? 'slider' : 'teaser') +
        '_body flexbox '
      "
      :data-tpl="computedData.teasertype"
      v-if="computedData.teasers && computedData.teasertype.includes('teaser')"
      :style="'width:100%'"
    >
      <!-- :style="'width:' + 100 * computedData.teasers.length + 'vw'" -->
      <teaser
        @swipeteaser="goToNextSlide($event)"
        :class="
          'dmaperformance__' +
          (computedData.teasertype.includes('slider') ? 'slider' : 'teaser') +
          '--' +
          (computedData.teasertype.includes('slider') ? 'slider' : 'teaser') +
          '_' +
          (computedData.teasertype.includes('slider') ? 'slide' : 'teaser') +
          ' ' +
          (computedData.teasertype.includes('slider') ? 'slide' : 'teaser') +
          i +
          ' ' +
          computedData.teasertype +
          ' ' +
          (activeSlide === i ? 'active' : '') +
          (teaser === objNextSlide ? ' next' : '') +
          (teaser === objPrevSlide ? ' prev' : '') +
          ' '
        "
        :hasFlags="computedData.hasFlags"
        :data="teaser"
        :type="computedData.teasertype"
        v-for="(teaser, i) in computedData.teasers"
        :key="
          'teaser-' +
          computedData.teasertype +
          '-' +
          computedData.intViewportId +
          i
        "
      />
    </div>
    <div
      class="btn next-vp"
      @mouseleave="toggleClass($event.target, 'active', 300)"
      @mouseenter="toggleClass($event.target, 'active', 300)"
      v-if="
        computedData.teasers &&
        computedData.teasertype !== 'price-table-teaser' &&
        (computedData.teasertype.includes('teaser') ||
          computedData.teasertype == 'city-slider')
      "
      @click="
        toggleClass($event.target, 'active', 300);
        goToNextViewport($event);
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="arrow"
        width="14.988"
        height="15.609"
        viewBox="0 0 14.988 15.609"
      >
        <defs></defs>
        <g transform="translate(-6 -5.379)">
          <path class="a" d="M7.5,18H18.866" transform="translate(0 -4.817)" />
          <path
            class="b"
            d="M18,7.5l5.683,5.683L18,18.866"
            transform="translate(-4.817 0)"
          />
        </g>
      </svg>
    </div>

    <div class="bg-wrapper" v-if="computedData.teasertype === 'city-slider'" />

    <div v-if="computedData.teasertype == 'city-slider' && objActiveSlide.alias != 'freib-e'" class="stoerer avd big" >
    
      <p><strong>Aufgepasst!</strong>Ab sofort bekommen alle Süwag2GO Kunden, die Mitglieder des Automobilclubs von Deutschland sind, einmalig 100 Freiminuten geschenkt!<br/>
      Jetzt schnell registrieren und im Nutzerkonto die AvD Mitgliedskarte hochladen und auf geht's zur nächsten Spritztour.</p>
          <img src="https://suewag2go-api.dma.do/files/suewag2go/avd_2Dlogo_CMYK_r.png" style="display:block;margin:0 auto" />
        </div>
  </section>
  <div v-if="computedData.teasertype == 'city-slider' && objActiveSlide.alias == 'freib-e'" class="stoerer avd big" >
    
    <p><strong>Werde Teil unseres Teams!</strong>Als Teil unseres Ladeteams sorgst Du dafür, dass unsere E-Roller, auch in Freiburg, immer einsatzbereit sind.</p>
    <p><a href="https://jobs.suewag.com/de/job/Werkstudent-Neue-Mobilitaetsloesungen-Suewag2GO-m%7Cw%7Cd-Freiburg-im-Breisgau?id=5142&utm_source=webseite&utm_medium=link&utm_campaign=suewag2go-stelle-2025&content=stelle-freiburg" target="_blank" style="color: #d40133 ;text-decoration:underline"><strong>Lese unsere ausführliche Stellenbeschreibung auf jobs.suewag.de und bewirb dich</strong></a>. </p>
        <!-- <img src="https://suewag2go-api.dma.do/files/suewag2go/avd_2Dlogo_CMYK_r.png" style="display:block;margin:0 auto" /> -->
      </div>
  <div v-if="computedData.teasertype == 'city-slider'" class="stoerer avd mob" >
    <!-- <p><strong>Aufgepasst!</strong>Ab sofort bekommen alle Süwag2GO Kunden, die <span>Mitglieder des Automobilclubs von Deutschland</span> sind, einmalig <span>100 Freiminuten geschenkt</span>!<br/>

    Jetzt schnell registrieren und im Nutzerkonto die <span>AvD Mitgliedskarte </span>hochladen und auf geht's zur nächsten Spritztour.</p> -->
    <p><strong>Aufgepasst!</strong>Ab sofort bekommen alle Süwag2GO Kunden, die Mitglieder des Automobilclubs von Deutschland sind, einmalig 100 Freiminuten geschenkt!<br/>
      Jetzt schnell registrieren und im Nutzerkonto die AvD Mitgliedskarte hochladen und auf geht's zur nächsten Spritztour.</p>
        <img src="https://suewag2go-api.dma.do/files/suewag2go/avd_2Dlogo_CMYK_r.png" style="display:block;margin:0 auto" />
      </div>
</template>
<script>
import { useRouter, useRoute } from 'vue-router'

import { computed, ref, watch, onMounted } from 'vue'
import Teaser from '../views/Teaser.vue'
import Slide from '../views/Slide.vue'
import Track from '../views/Track.vue'
import {
  toggleClass,
  scrollBefore,
  redirect,
  isElementInViewport
} from '../use/useHelperFunctions.js'

export default {
  name: 'ContentBox',
  components: { Teaser, Slide, Track },
  props: {
    data: {
      type: Object
    }
  },
  setup (props, context) {
    // const { route, href, isActive, isExactActive, navigate } = useLink(props)
    // .ENV Configuration
    const route = useRoute()
    const router = useRouter()
    const activeSlide = ref(0)
    const rotationindex = ref(0)
    const isSliding = ref(false)
    const isFading = ref(false)
    const slideEvent = ref(false)
    const touchstartPoints = ref(false)
    const swipeEvent = ref(false)
    const touchendPoints = ref(false)
    function willRotate (i) {
      if (computedData.value.infinite && i < activeSlide.value - 1) {
        return true
      }
      // if (computedData.value.infinite && ) {
      //   return true
      // }
      return false
    }
    
    const computedData = computed(() => {
      const $return = {}
      var content
      var prop
      if (props.data && props.data.attributes) {
        for (prop in props.data.attributes) {
          content = props.data.attributes[prop]
            ? props.data.attributes[prop]
            : false

          $return[prop] = content
        }
      }

      if (props.data) {
        for (prop in props.data) {
          content = props.data[prop] ? props.data[prop] : false

          $return[prop] = content
        }
      }
      
      if ($return.teasertype === 'content-slider-black') {
        $return.hasFlags = 'slide'
      }

      if ($return.teasertype === 'header-slider') {
        $return.hasFlags = false// 'slider'
      }

      if ($return.teasertype === 'city-slider') {
        $return.teasers.forEach((element, index) => {
          if (element.url && '/' + route.params.id === element.url) {
            activeSlide.value = index
          }
        })
        $return.infinite = true
      }
      if ($return.teasertype === 'header-slider') {
        // $return.teasers.forEach((element, index) => {
        if (!$return.intViewportId) {
          // $return.teasers[index].buttonText = 'Foo'
          $return.intViewportId = 1
          // }
        }
        $return.infinite = true
      }
      console.log($return)
      console.log($return.teasertype)
      return $return
    })

    const objActiveSlide = computed(() => {
      if (computedData.value.teasers) {
        return computedData.value.teasers[activeSlide.value]
      }

      return false
    })
    const isContentSliderBlackOnDesktop = computed(() => {
      return (computedData.value.teasertype === 'content-slider-black' && ((window.innerWidth || document.documentElement.clientWidth) >= 1024))
    })
    const nextButtonIsDisabled = computed(() => {
      return (computedData.value.teasertype.includes('slider') &&
          activeSlide.value === computedData.value.teasers.length - 1 &&
          !computedData.value.infinite) ||
            (isContentSliderBlackOnDesktop.value && activeSlide.value === computedData.value.teasers.length - 2 && !computedData.value.infinite)
    })
    const computedStyle = computed(() => {
      if (computedData.value.teasers) {
        switch (props.data.teasertype) {
          case 'city-slider':
            break
          case 'content-slider':
            break
          case 'content-slider-black':
            break
          case 'header-slider':
            break
          default:
            break
        }
      }
      return null
    })
    const objPrevSlide = computed(() => {
      if (computedData.value.teasers) {
        if (!computedData.value.infinite) {
          if (computedData.value.teasers[activeSlide.value - 1]) {
            return computedData.value.teasers[activeSlide.value - 1]
          }
        } else {
          if (computedData.value.teasers[activeSlide.value - 1]) {
            return computedData.value.teasers[activeSlide.value - 1]
          } else {
            return computedData.value.teasers[
              computedData.value.teasers.length - 1
            ]
          }
        }
      }
      return false
    })
    const objNextSlide = computed(() => {
      if (computedData.value.teasers) {
        if (!computedData.value.infinite) {
          if (computedData.value.teasers[activeSlide.value + 1]) {
            return computedData.value.teasers[activeSlide.value + 1]
          }
        } else {
          if (computedData.value.teasers[activeSlide.value + 1]) {
            return computedData.value.teasers[activeSlide.value + 1]
          } else {
            return computedData.value.teasers[0]
          }
        }
      }
      return false
    })
    const isEdgeSlide = computed(() => {
      if (computedData.value.teasers && isSliding.value !== false) {
        if (computedData.value.infinite) {
          if (
            activeSlide.value + 1 >= computedData.value.teasers.length ||
            activeSlide.value - 1 <= 0
          ) {
            return true
          }
        }
      }
      return false
    })
    const goToNextSlide = (
      event,
      forwardOrtargetSlideId = true,
      isHover = false
    ) => {
      if (event && event.index) {
        forwardOrtargetSlideId = event.index
      }
      if (swipeEvent.value) {
        event.direction = swipeEvent.value ? swipeEvent.value.direction : false
      }
      if (event.direction) {
        if (slideEvent.value !== false) {
          return false
        }
        slideEvent.value = true
        if (!isHover) {
          setTimeout(() => {
            slideEvent.value = false
          }, 500)
        }

        if (event.direction === 'left') {
          if (nextButtonIsDisabled.value) {
            return false
          }
          forwardOrtargetSlideId = true
        } else {
          forwardOrtargetSlideId = false
        }
      }

      isSliding.value = true
      setTimeout(() => {
        isSliding.value = false
      }, 300)
      var targetSlideId = false
      var forward = false

      if (typeof forwardOrtargetSlideId === 'number') {
        targetSlideId = forwardOrtargetSlideId
      } else {
        forward = forwardOrtargetSlideId
      }
      if (event.target && event.target.classList && event.target.classList.contains('btn--primary')) {
        return false
      }
      if (targetSlideId === false) {
        if (forward) {
          if (computedData.value.infinite) {
            if (activeSlide.value + 1 < computedData.value.teasers.length) {
              activeSlide.value = ++activeSlide.value
            } else {
              rotationindex.value++
              activeSlide.value = 0
            }
          } else {
            if (activeSlide.value + 1 < computedData.value.teasers.length) {
              activeSlide.value = ++activeSlide.value
            }
          }
        } else {
          if (computedData.value.infinite) {
            if (activeSlide.value - 1 >= 0) {
              activeSlide.value = --activeSlide.value
            } else {
              // objPrevSlide.value.style.opactity = 0
              // ({
              //   objPrevSlide.value.style.opactity = 1
              // }, 300)
              activeSlide.value = computedData.value.teasers.length - 1
              rotationindex.value--
            }
          } else {
            if (activeSlide.value - 1 >= 0) {
              activeSlide.value = --activeSlide.value
            }
          }
        }
      } else {
        if (computedData.value.infinite) {
          if (activeSlide.value < computedData.value.teasers.length) {
            // activeSlide.value = ++activeSlide.value
          } else {
            rotationindex.value++
            activeSlide.value = 0
          }
        } else {
          if (activeSlide.value + 1 < computedData.value.teasers.length) {
            // activeSlide.value = ++activeSlide.value
          }
        }
        if ((activeSlide.value) !== forwardOrtargetSlideId || (activeSlide.value === computedData.value.teasers.length)) {
          if (activeSlide.value < computedData.value.teasers.length) {
            activeSlide.value = ++activeSlide.value
          }
          setTimeout(() => {
            goToNextSlide(event, activeSlide.value)
          }, 400)
        }
        // activeSlide.value = targetSlideId
      }
      if (
        (computedData.value.teasertype !== 'header-slider' &&
          event.event &&
          event.event.type === 'click' &&
          computedData.value.teasertype === 'city-slider') ||
        (computedData.value.teasertype !== 'header-slider' && event.direction)
      ) {
        var objTarget = computedData.value.teasers[activeSlide.value]

        if (objTarget.url) {
          router.push(objTarget.url)
        }
      }
    }
    const goToNextSlideBack = (
      event,
      forwardOrtargetSlideId = true,
      isHover = false
    ) => {

      isFading.value = !isFading.value;
      setTimeout(()=> {
        isFading.value= !isFading.value;

      },1000)
      if (event && event.index) {
        forwardOrtargetSlideId = event.index
      }
      if (swipeEvent.value) {
        event.direction = swipeEvent.value ? swipeEvent.value.direction : false
      }
      if (event.direction) {
        if (slideEvent.value !== false) {
          return false
        }
        slideEvent.value = true
        if (!isHover) {
          setTimeout(() => {
            slideEvent.value = false
          }, 500)
        }

        if (event.direction === 'left') {
          if (nextButtonIsDisabled.value) {
            return false
          }
          forwardOrtargetSlideId = true
        } else {
          forwardOrtargetSlideId = false
        }
      }

      isSliding.value = true
      setTimeout(() => {
        isSliding.value = false
      }, 300)
      var targetSlideId = false
      var forward = false

      if (typeof forwardOrtargetSlideId === 'number') {
        targetSlideId = forwardOrtargetSlideId
      } else {
        forward = forwardOrtargetSlideId
      }
      if (event.target && event.target.classList && event.target.classList.contains('btn--primary')) {
        return false
      }
      if (targetSlideId === false) {
        if (forward) {
          if (computedData.value.infinite) {
            if (activeSlide.value + 1 < computedData.value.teasers.length) {
              activeSlide.value = ++activeSlide.value
            } else {
              rotationindex.value++
              activeSlide.value = 0
            }
          } else {
            if (activeSlide.value + 1 < computedData.value.teasers.length) {
              activeSlide.value = ++activeSlide.value
            }
          }
        } else {
          if (computedData.value.infinite) {
            if (activeSlide.value - 1 >= 0) {
              activeSlide.value = --activeSlide.value
            } else {
              // objPrevSlide.value.style.opactity = 0
              // ({
              //   objPrevSlide.value.style.opactity = 1
              // }, 300)
              activeSlide.value = computedData.value.teasers.length - 1
              rotationindex.value--
            }
          } else {
            if (activeSlide.value - 1 >= 0) {
              activeSlide.value = --activeSlide.value
            }
          }
        }
      } else {
        if (computedData.value.infinite) {
          if (activeSlide.value < computedData.value.teasers.length) {
            // activeSlide.value = ++activeSlide.value
          } else {
            rotationindex.value++
            activeSlide.value = 0
          }
        } else {
          if (activeSlide.value + 1 < computedData.value.teasers.length) {
            // activeSlide.value = ++activeSlide.value
          }
        }
        if ((activeSlide.value) !== forwardOrtargetSlideId || (activeSlide.value === computedData.value.teasers.length)) {
          if (activeSlide.value < computedData.value.teasers.length) {
            activeSlide.value = ++activeSlide.value
          }
          setTimeout(() => {
            goToNextSlide(event, activeSlide.value)
          }, 400)
        }
        // activeSlide.value = targetSlideId
      }
      if (
        (computedData.value.teasertype !== 'header-slider' &&
          event.event &&
          event.event.type === 'click' &&
          computedData.value.teasertype === 'city-slider') ||
        (computedData.value.teasertype !== 'header-slider' && event.direction)
      ) {
        var objTarget = computedData.value.teasers[activeSlide.value]

        if (objTarget.url) {
          router.push(objTarget.url)
        }
      }
    }
    function goToNextViewport (event, direction = 'down') {
      // event.preventDefault()
      swipeEvent.value = { event: event, direction: direction }
    }
    onMounted(() => {
      // callAPI()
      document.addEventListener('touchstart', handleTouchStartComponent, {
        passive: false,
        cancelable: true
      })

      // document.addEventListener('touchmove', handleTouchMoveComponent, { })
      document.addEventListener('touchend', handleTouchMoveComponent, {
        passive: false,
        cancelable: true
      })
    })

    function calculateSwipeDirection () {
      const minimum = 15
      var xDiff = touchstartPoints.value.clientX - touchendPoints.value.clientX
      var yDiff = touchstartPoints.value.clientY - touchendPoints.value.clientY
      xDiff = Math.abs(Math.abs(xDiff))
      yDiff = Math.abs(Math.abs(yDiff))

      if (xDiff > minimum || yDiff > minimum) {
        if (xDiff < yDiff) {
          if (
            touchstartPoints.value.clientY - minimum >
            touchendPoints.value.clientY
          ) {
            return 'down'
          } else if (
            touchstartPoints.value.clientY <
            touchendPoints.value.clientY - minimum
          ) {
            return 'up'
          }
        } else {
          if (
            touchstartPoints.value.clientX <
            touchendPoints.value.clientX - minimum
          ) {
            return 'right'
          } else if (
            touchstartPoints.value.clientX - minimum >
            touchendPoints.value.clientX
          ) {
            return 'left'
          }
        }
      }
      return false
    }

    function handleTouchStartComponent (event) {
      // event.preventDefault()
      // event.stopPropagation()
      const isDownArrow = event.target.classList.contains('next-vp')
      if (event.type === 'touchstart' && !isDownArrow) {
        touchstartPoints.value = event.touches[0]
      }
      return false
    }
    function handleTouchMoveComponent (event) {
      // event.preventDefault()
      // event.stopPropagation()
      const isDownArrow = event.target.classList.contains('next-vp')
      if (event.type === 'touchend' && !isDownArrow) {
        touchendPoints.value = event.changedTouches[0]

        var dir = calculateSwipeDirection()
        // return { event: event, direction: dir }
        swipeEvent.value = { event: event, direction: dir }
      }

      return false
    }
    function changeViewPortOrSwipe (ev) {
      const direction = ev.direction
      const eventTarget = ev.event.target
      const event = ev.event
      var targetViewportNode = false
      const isDownArrow = eventTarget.classList.contains('next-vp')
      var currentViewportId = 0
      if (direction) {
        var currentViewport = event.target.closest('section')
        if (!currentViewport) {
          return false
        }

        if (currentViewport.getBoundingClientRect().top > 0) {
          currentViewportId = currentViewport.id
            ? parseInt(currentViewport.id.replace('vp', ''))
            : false

          if (direction === 'down') {
            targetViewportNode = document.getElementById(
              'vp' + currentViewportId
            )
            // return false
          } else if (direction === 'up') {
            targetViewportNode = document.getElementById(
              'vp' + (currentViewportId - 1)
            )
            // return false
          } else {
            if (currentViewportId !== computedData.value.intViewportId) {
              return false
            }
            targetViewportNode = false
          }
        } else {
          currentViewportId = currentViewport.id
            ? parseInt(currentViewport.id.replace('vp', ''))
            : false
          if (currentViewportId !== computedData.value.intViewportId) {
            return false
          }

          // var rect =
          // if (direction === 'down' && targetViewportNode === false) {
          if (direction === 'down') {
            targetViewportNode = document.getElementById(
              'vp' + (currentViewportId + 1)
            )
            // } else if (direction === 'up' && targetViewportNode === false) {
          } else if (direction === 'up') {
            targetViewportNode = document.getElementById(
              'vp' + (currentViewportId - 1)
            )
          } else {
            targetViewportNode = false
          }
        }
        if (targetViewportNode) {
          var offset = -40
          // var scrollpos = 40
          if (direction === 'up') {
            offset = 40
          }

          if (isElementInViewport(targetViewportNode, offset) || isDownArrow) {
            if (event.cancelable) {
              event.preventDefault()
            }

            scrollBefore(targetViewportNode, 0)
          }
        }

        currentViewport = event.target.closest('section')
        // if (isElementInViewport(targetViewportNode, offset)
        if (direction === 'left') {
          goToNextSlide(event, false)
        } else if (direction === 'right') {
          goToNextSlide(event, true)
        }
        // if (!)
        //   targetViewportNode = false
        //   event.stopPropagation()
        //   if (swipeEvent.value === false) {
        //     swipeEvent = { event: event, touchDirection: touchDirection, id: currentViewportId }
        //     setTimeout(() => { swipeEvent = false }, 200)
        //   }
        //   event.preventDefault()
        //   return swipeEvent
        // }
      }
    }
    watch(
      () => swipeEvent.value,
      (to) => {
        if (to) {
          changeViewPortOrSwipe(to)

          // changeViewPortOrSwipe(to)
        }
        // }
      }
    )

    return {
      
      computedData,
      router,
      activeSlide,
      goToNextSlide,
      goToNextSlideBack,
      objPrevSlide,
      objNextSlide,
      objActiveSlide,
      computedStyle,
      goToNextViewport,
      toggleClass,
      isEdgeSlide,
      willRotate,
      rotationindex,
      redirect,
      isContentSliderBlackOnDesktop,
      nextButtonIsDisabled,
      isFading
    }
  },
  methods: {
    composeLinkTitle (alias) {
      
      switch (alias) {
        case '/frank-e': return 'Frankfurt'
        case '/taun-e': return 'Bad Homburg'
        case '/newb-e': return 'Neuwied'
        case '/meenz-e': return 'Mainz'
        case '/lub-e': return 'Ludwigsburg'
        case '/lahn-e': return 'Lahnstein'

        case '/wies-e': return 'Wiesbaden'
        case '/freib-e': return 'Freiburg'
      }
      return 'Weiter'
    }
  }
}
</script>
